<template class="flex">
  <div class="elevation-5 card-album">
    <img
      :src="'https://creativeapi.schaffenlab.com/api/download?image=' + imagem"
    />
    <div class="pa-5">
      <p>{{ title }}</p>
      <div class="d-flex align-center mt-3">
        <v-icon class="tamanho-icon">mdi-calendar-multiple-check</v-icon>
        <p class="ml-3">{{ data }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardAlbum",
  props: ["title", "data", "imagem"],
  methods: {
    removeFile() {
      this.$emit("clickRemove", true);
    },
  },
};
</script>

<style scoped>
.tamanho-icon {
  font-size: 25px !important;
}

img {
  max-width: 100%;
  display: block;
}

.card-album {
  border-radius: 10px;
}

.flex > div {
  flex: 1 1 200px;
  margin: 10px;
}

.flex > div:nth-child(1) {
  display: grid;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 600px;
}

.flex > div:nth-child(1) > div {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  grid-column: 1;
  grid-row: 2/3;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.flex > div:nth-child(1) > img {
  grid-column: 1;
  grid-row: 1/3;
  border-radius: 10px;
}

.card-album:nth-child(n + 2) > img {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.card-album:nth-child(n + 2) > div > div > i {
  color: black !important;
}
</style>
